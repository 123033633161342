import * as React from "react";
import { useLocation } from "react-router-dom";
import { PageBottomWrapper, PageWrapper } from "../../components/page";
import { ButtonFullWidth, ButtonInput } from "../../components";
import { IconWithText } from "../../components/icon.with.text";
import { useUserData } from "../../../data.providers/user.data.context";

import axios from "axios";



interface Trainer {
  id: string,
  email: string,
  displayName: string,
  pictureUrl: object,
}

interface Training {
  id: string,
  date: string,
  time: string,
  address: string,
  trainer: Trainer,
}

const weekDaysInLithuanian = [
  'Sekmadienis',
  'Pirmadienis',
  'Antradienis',
  'Trečiadienis',
  'Ketvirtadinis',
  'Penktadienis',
  'Šeštadienis'
];

const monthsInLithuanian = [
  "Sausio",
  "Vasario",
  "Kovo",
  "Balandžio",
  "Gegužės",
  "Birželio",
  "Liepos",
  "Rugpjūčio",
  "Rugsėjo",
  "Spalio",
  "Lapkričio",
  "Gruodžio"
];

const getDateInText = (date: string) => `${monthsInLithuanian[(new Date(date)).getMonth()]} ${(new Date(date)).getDate()}`;

const getWeekDay = (date: string) => weekDaysInLithuanian[(new Date(date)).getDay()];

const formatTimeString = (time: string) => time.slice(0, -3);

const HandleOnClick = (id: string) => {
  axios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/${id}/cancel`,
    withCredentials: true
  }).then((response) => {
    if(response.data.msg) {
        console.log('Not Authenticated');
    } else {
      window.location.assign(`${process.env.REACT_APP_FRONTEND_URL}/trainings`);
    }
  }).catch((error) => {
    console.error('Error updating user:', error);
  });
};

export const TrainingCancellation: React.FC = () => {
  const { user } = useUserData();
  const { state } = useLocation();
  const training: Training = state.training;
  return ( state &&
    <>
      <PageWrapper>
        <IconWithText 
        icon="close" 
        header="Ar tikrai norite atšaukti treniruotę?" 
        info={`
        ${formatTimeString(training.time)}, 
        ${getWeekDay(training.date)}, 
        ${getDateInText(training.date)}, 
        ${training.trainer.displayName}, 
        ${training.address}
        `}
        secondary="Pinigai, sumokėti už treniruotę, bus grąžinti per 2 darbo dienas."
        />
      </PageWrapper>
      <PageBottomWrapper>
        
        <ButtonFullWidth name='Grįžti į treniruotes' path={`/orders/`} ></ButtonFullWidth>
        <ButtonInput onClick={()=>{HandleOnClick(training.id)}}>Sutinku, atšaukti treniruotę</ButtonInput>
      </PageBottomWrapper>
    </>
  );
}