import React from 'react'
import axios from 'axios';

import styles from './trainer.training.module.scss'
import { PageTitle, PageWrapper } from '../../components/page'
import { ButtonWithIcon, TrainingCard, UserCard } from '../../components'
import { ReactComponent as ReceiptSvg } from './receipt_long.svg';
import { ReactComponent as LoopSvg } from './loop.svg';
import { ReactComponent as CancelSvg } from './cancel.svg';
import { Link, useParams } from 'react-router-dom';


interface User {
    id: string,
    email: string,
    phoneNumber: string,
    displayName: string,
    pictureUrl: string,
  }
  
  interface Training {
    id: string,
    date: string,
    time: string,
    address: string,
    status: 'pending' | 'success' | 'failed',
    user: User,
    orderId: string
  }

export const TrainerTraining = () => {
    const { trainingId } = useParams();
    const [training, setTraining] = React.useState<Training| null>(null)
    React.useEffect(() => {
        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/trainer-training/${trainingId}`,
          withCredentials: true
        }).then((response) => {
            setTraining(response.data);
        })
      }, []);

    return ( training &&
        <PageWrapper>
            <PageTitle backButton>Treniruotė</PageTitle>
            <div className={styles.contentWrapper}>
                <TrainingCard
                    training={{
                        id: training.id,
                        time: training.time,
                        date: training.date,
                        address: training.address,
                        status: training.status
                    }}
                    userType='trainer'
                />

                <UserCard
                    user={{
                        displayName: training.user.displayName,
                        phoneNumber: training.user.phoneNumber,
                        pictureUrl: training.user.pictureUrl,
                        email: training.user.email,
                        id: '1'
                    }}
                />
                <div className={styles.buttonsWrapper}>
                    <Link to={'/order/'+ training.orderId}>
                        <ButtonWithIcon icon={<ReceiptSvg />} name='Peržiūrėti mokėjimą' />
                    </Link>
                    {false && <>
                        <ButtonWithIcon icon={<LoopSvg />} name='Peržiūrėti abonimentą' />
                        <Link to='/trainings/cancellation'>
                            <ButtonWithIcon icon={<CancelSvg />} name='Atšaukti treniruotę' />
                        </Link>
                    </>
                    }
                </div>
            </div>
        </PageWrapper>
    )
}
