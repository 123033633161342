import React, { useState } from 'react';
import styles from './modal.module.scss';  // Import the SCSS module

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <div className={`${styles.popupWrapper} ${isOpen ? styles.open : ''}`} onClick={onClose}>
      <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

