import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './subscription.edit.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';
import { ButtonFullWidth, Title, UserCard } from '../../components';
import { DayInput } from '../../components/subscription';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

type TDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface Isubscription {
    day: TDay,
    times: string[]
}

export const SubscriptionEdit = () => {
    const [subscriptions, setSubscriptions] = useState<Isubscription[]>([])
    const { state } = useLocation();

    function handleDaySelector(ev: ChangeEvent<HTMLInputElement>) {
        const value = +ev.target.value as TDay;
        setSubscriptions((prev) => {
            const selectedDay = prev.find((item) => item.day === value)
            if (Boolean(selectedDay)) {
                return prev.filter((item) => item.day !== value)
            }
            return [
                ...prev,
                {
                    day: value,
                    times: ['']
                }
            ]
        })
    }

    function addTimeInput(value: TDay) {
        setSubscriptions((prev) => {
            return prev.map((item) => {
                if (item.day === value) {
                    return {
                        ...item,
                        times: [
                            ...item.times || [],
                            ''
                        ]
                    }
                }
                return item
            })
        })
    }

    function removeTimeInput(value: TDay, index: number) {
        setSubscriptions((prev) => {
            return prev.map((item) => {
                if (item.day === value) {
                    return {
                        ...item,
                        times: item.times.filter((_, i) => index !== i)
                    }
                }
                return item
            })
        })
    }

    function handleTimeInputValue({ day, value, index }: { day: TDay, value: string, index: number }) {
        setSubscriptions((prev) => {
            return prev.map((item) => {
                if (item.day === day) {
                    return {
                        ...item,
                        times: item.times.map((item, i) => {
                            if (index === i) {
                                return value
                            }
                            return item
                        })
                    }
                }
                return item
            })
        })
    }

    function handleSubmittingORder() {
        if (!Boolean(subscriptions.find((item) => item.times.includes('')))) {
            console.log('subscriptions', subscriptions)
        }
    }

    useEffect(() => {
        if (state)
            setSubscriptions(state)
    }, [state])
    

    return (
        <PageWrapper>
            <PageTitle backButton>Keisti Laiką</PageTitle>
            <div className={styles.infoSection}>
                <UserCard
                    user={{
                        id: '1',
                        displayName: "Kostas Petrošius",
                        phoneNumber: "+370 123 45 678",
                        email: "kostas@gmail.com",
                        pictureUrl: "profile.png",
                    }}
                />
                <Title size='big'>Treniruočių laikas</Title>
            </div>
            <div className={classNames(styles.weekInputsContainer, { [styles.daysSelected]: subscriptions.length > 0 })}>
                <DayInput
                    label='Pirmadienį'
                    value={0}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Antradienį'
                    value={1}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Trečiadienį'
                    value={2}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Ketvirtadienį'
                    value={3}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Penktadienį'
                    value={4}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Šeštadienį'
                    value={5}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
                <DayInput
                    label='Sekmadienį'
                    value={6}
                    onChange={handleDaySelector}
                    subscriptions={subscriptions}
                    addTimeInput={addTimeInput}
                    removeTimeInput={removeTimeInput}
                    handleTimeInputValue={handleTimeInputValue}
                />
            </div>
            {subscriptions.length > 0 && (
                <div className={styles.orderButtonWrapper}>
                    <ButtonFullWidth
                        name='Pateikti užklausą'
                        onClick={handleSubmittingORder}
                    />
                </div>
            )}
        </PageWrapper>
    )
}
