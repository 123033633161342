import * as React from "react";
import styles from "./calendar.day.module.scss";
import classNames from "classnames";

interface ICalendarDay {
  weekDay: string,
  monthNumber: number,
  onClick?: () => void,
  active: boolean,
  disabled: boolean,
}

export const CalendarDay: React.FC<ICalendarDay> = (props) => {
  const { weekDay, monthNumber, onClick, active, disabled } = props;
  const handleOnClick = () => !disabled && onClick && onClick();

  return (        
    <div onClick={handleOnClick} className={styles.calendarDay}>
      <div className={classNames(styles.calendarWeekName, { [styles.disabled] : disabled, })}>
        {weekDay}
      </div>
      <div className={classNames(styles.calendarDayNumber, { [styles.active] : active, [styles.disabled] : disabled, })}>
        {monthNumber}
      </div>
    </div>
  );
}