import * as React from "react";
import { PageTitle, PageWrapper } from "../../../components/page";
import axios from "axios";
import { FrameButton, Navbar } from "../../../components";
import { OrderCard, OrdersCounter } from "../../../components/order";

interface User {
    id: string,
    email: string,
    displayName: string,
    pictureUrl: string,
    phoneNumber: string,
    type: string,
}

interface Order {
    id: string,
    status: 'success' | 'pending' | 'failed',
    trainer?: User,
    user?: User,
    createdAt: string,
    timeSlotsCount: number
}

export const UserOrders: React.FC = () => {
    const [orders, setOrders] = React.useState<Array<Order>>([])

        React.useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/user-orders`,
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            if(response.data.msg) {
                console.log('Not Authenticated');
            } else {
                setOrders(response.data);
            }
        })

    }, []);
    return (<>
        <PageWrapper className={'navbarPadding'}>
            <PageTitle>Mokėjimai</PageTitle>
            <OrdersCounter count={orders.length} />
            {orders.map((item: Order) => (
                <>
                    <OrderCard order={item} />
                    <FrameButton name='Peržiūrėti užsakymą' path={'/order/' + item.id}  />
                </>
            ))}
        </PageWrapper>
        <Navbar active='orders' />
        </>
    );
}