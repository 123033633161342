import { Frame } from "../frame";
import styles from "./payment.total.price.module.scss";

interface IPaymentTotalPrice {
    price: number;
}
const lithuanianTotalPriceText = 'Viso mokėti';

export const PaymentTotalPrice: React.FC<IPaymentTotalPrice> = ({ price }) =>
(<Frame className={styles.paymentTotalPriceContainer} >
    <div className={styles.floatLeft}>{lithuanianTotalPriceText}</div> 
    <div className={styles.floatRight}>&#8364; {price}</div>
</Frame>)
