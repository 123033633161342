import React from 'react'
import axios from 'axios'
import styles from './user.subscription.module.scss'
import { PageTitle, PageWrapper } from '../../../components/page'
import { SubscriptionCard } from '../../../components/subscription'
import { FrameButton, Title, UserCard } from '../../../components'
import { OrderCard } from '../../../components/order'
interface User {
    id: string,
    email: string,
    displayName: string,
    pictureUrl: string,
    phoneNumber: string,
    type: string,
}

interface Order {
    id: string,
    status: 'success' | 'pending' | 'failed',
    trainer?: User,
    user?: User,
    createdAt: string,
    timeSlotsCount: number
}

export const UserSubscription = () => {
    const [orders, setOrders] = React.useState<Array<Order>>([])

    React.useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/user-orders`,
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            if (response.data.msg) {
                console.log('Not Authenticated');
            } else {
                setOrders(response.data);
            }
        })

    }, []);

    return (
        <PageWrapper>
            <PageTitle backButton>Abonementas</PageTitle>
            <UserCard
                user={{
                    id: '1',
                    displayName: "Kostas Petrošius",
                    phoneNumber: "+370 123 45 678",
                    location: "SportGates",
                    pictureUrl: "profile.png",
                }}
                className={styles.subscriptionsUserCard}
            />
            <SubscriptionCard
                subscription={{
                    id: '1',
                    status: 'success',
                    activeAt: '2024-07-01T12:35:55.941Z',
                    validAt: '2024-07-02T12:35:55.941Z',
                    location: 'SportGates',
                    isConfirmed: true,
                    days: [{ day: 'Pirmadienis', times: [['12:00', '13:00'], ['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00', '13:00'], ['14:00', '15:00']] }]
                }}
            />
            {orders.length > 0 && (
                <>
                    <Title size='small' className={styles.ordersTitle}>Abonemento mokėjimai</Title>
                    {orders.map((item: Order) => (
                        <>
                            <OrderCard order={item} key={item.id} />
                            <FrameButton name='Peržiūrėti užsakymą' path={'/order/' + item.id}  />
                        </>
                    ))}
                </>
            )}
        </PageWrapper>
    )
}
