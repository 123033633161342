import * as React from "react";
import styles from "./training.module.scss";
import { ReactComponent as LocationSVG } from "./location.svg";
import { ReactComponent as ProfileSVG } from "./profile.svg";
import { Frame } from "../frame";
import { Field } from "../field";
import { Status } from "../status";
import { FrameButton } from "../button";

interface Trainer {
  id: string,
  email: string,
  displayName: string,
  pictureUrl: object,
}
interface Training {
  id: string,
  date: string,
  time: string,
  address: string,
  status?: 'pending' | 'success' | 'failed';
  trainer?: Trainer,
}

interface ITraining {
  training: Training;
  userType: 'trainer' | 'client'
}

const weekDaysInLithuanian = ['Sekmadienis', 'Pirmadienis', 'Antradienis', 'Trečiadienis', 'Ketvirtadinis', 'Penktadienis', 'Šeštadienis'];

const monthsInLithuanian = [
  "Sausio",
  "Vasario",
  "Kovo",
  "Balandžio",
  "Gegužės",
  "Birželio",
  "Liepos",
  "Rugpjūčio",
  "Rugsėjo",
  "Spalio",
  "Lapkričio",
  "Gruodžio"
];

const formatTimeString = (time: string) => time.slice(0, -3);

const getWeekDay = (date: string) => weekDaysInLithuanian[(new Date(date)).getDay()];

const getDateInText = (date: string) => `${monthsInLithuanian[(new Date(date)).getMonth()]} ${(new Date(date)).getDate()}`;

const getStatusLithuanianTranslation = {
  success: 'Apmokėta sėkmingai',
  pending: 'Laukiama apmokėjimo',
  failed: 'Apmokėjimas nepavyko',
};


export const TrainingCard: React.FC<ITraining> = (props) => {
  const { training, userType } = props;
  return (
    <>
      <Frame className={styles.trainingContainer}>
        <div className={styles.dateTimeWrapper}>
          <div className={styles.timeColumn}>
            {formatTimeString(training.time)}
          </div>
          <div className={styles.dateColumn}>
            <div className={styles.weekDay}>{getWeekDay(training.date)}</div>
            <div className={styles.monthDay}>{getDateInText(training.date)}</div>
          </div>
        </div>
        <div className={styles.infoWrapepr}>
          {userType === 'client' ? (
            <Field icon={<ProfileSVG />} text={training.trainer?.displayName} size="small" />
          ) : (
            training?.status && <Field icon={<Status status={training?.status} />} text={getStatusLithuanianTranslation[training?.status]} size="small" />
          )}
          <Field icon={<LocationSVG />} text={training.address} size="small" />
        </div>
      </Frame>
      {userType === 'client' && (
        <FrameButton name='Atšaukti treniruotę' path={'/trainings/cancellation'} state={{ training }} />
      )}
    </>
  );
};
