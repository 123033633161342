
interface IStatus {
    status: 'pending' | 'success' | 'failed';
}


enum ColorCode {
    success = '#6BCD3C',
    pending = '#F4D42B',
    failed = '#FF5353',
}

const statusColorCodeMap = (status: string) => {
    if (status === 'success') {
        return ColorCode.success
    }
    if (status === 'pending') {
        return ColorCode.pending
    }
    return ColorCode.failed
}

const statusSvg = (status: string) => (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill={statusColorCodeMap(status)}/>
</svg>)

export const Status: React.FC<IStatus> = ({ status }) =>
    statusSvg(status) 
