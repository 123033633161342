import * as dateFns from "date-fns";

const monthsInLithuanian = [
    "Sausio",
    "Vasario",
    "Kovo",
    "Balandžio",
    "Gegužės",
    "Birželio",
    "Liepos",
    "Rugpjūčio",
    "Rugsėjo",
    "Spalio",
    "Lapkričio",
    "Gruodžio"
];

const convertDateForIos = (date: any) => {
    const arr = date.split(/[- :]/);
    date = new Date(arr[0], arr[1] - 1, arr[2]);
    return date;
}

const getDateInText = (date: string) => `${monthsInLithuanian[convertDateForIos(date).getMonth()]} ${convertDateForIos(date).getDate()}`;



export const dateFormat = (date: string, withTime?: boolean) => {
    if (withTime) {
        return `${dateFns.format(date, 'HH:mm')}, ${getDateInText(dateFns.format(date, 'yyyy-MM-dd'))}, ${dateFns.format(date, 'yyyy')}`
    }
    return `${getDateInText(dateFns.format(date, 'yyyy-MM-dd'))}, ${dateFns.format(date, 'yyyy')}`
}