import React, { ChangeEvent } from 'react'
import styles from './location.input.module.scss';
import { Frame } from '../frame';
import { RadioInput } from '../input';


interface ILocation {
    id: string,
    displayName: string;
}

interface ILocationInput {
    location: ILocation,
    handleLocationSelect: (location: ILocation) => void,
    checked: boolean,
}

export const LocationInput: React.FC<ILocationInput> = ({ location, handleLocationSelect, checked }) => {
    function onChange(ev: ChangeEvent<HTMLInputElement>) {
        if (ev.target.checked) {
            handleLocationSelect(location)
        }
    }

    return (
        <Frame className={styles.locationInput}>
            <RadioInput
                label={location.displayName}
                onChange={onChange}
                value={location.id}
                checked={checked}
            />
        </Frame>
    )
}
