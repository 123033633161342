import * as React from "react";
import styles from "./button.with.icon.module.scss";

interface IButtonWithIcon {
  name: string;
  onClick?: () => void;
  icon: React.ReactNode;
}

export const ButtonWithIcon: React.FC<IButtonWithIcon> = ({ icon, name, onClick }) => {
    function handleClick() {
        onClick && onClick()
    }
    return <button className={styles.buttonWithIcon} onClick={handleClick}>{icon}{name}</button>
}