import * as React from "react";
import * as dateFns from "date-fns";
import { useDebouncedCallback } from 'use-debounce';
import styles from "./calendar.days.module.scss";
import {CalendarDay} from "./calendar.day";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface ICalendarDays {
  setActiveDate: Function,
}

const weekDayMappings = ["Sk", "Pr", "An", "Tr", "Kt", "Pn", "Št"];

const getMonday = (d: Date) => dateFns.startOfWeek(d, { weekStartsOn: 1 });

const isDateDisabled = (date: Date) => dateFns.isBefore(date, dateFns.startOfDay(new Date()))

const getWeek = (dateFrom: Date) => {
  let workingDate = getMonday(dateFrom);
  const daysArray = dateFns.eachDayOfInterval({
    start: workingDate,
    end: dateFns.addDays(workingDate, 6)
  })
  return daysArray.map((date: Date) => ({ monthDay: date, weekday: date.getDay(), isDisabled: isDateDisabled(date) }));
}

const getformatedDate = (date: Date) => {
  return dateFns.format(date, "yyyy-MM-dd");
}

export const CalendarDays: React.FC<ICalendarDays> = (props) => {
  const {setActiveDate} = props;
  const [activeDay, setActiveDay] = React.useState(new Date());

  setActiveDate(activeDay);
  const [weeks, setWeeks] = React.useState([
    getWeek(new Date()),
    getWeek(dateFns.addDays(new Date(), 7)),
    getWeek(dateFns.addDays(new Date(), 14)),
    getWeek(dateFns.addDays(new Date(), 21))
  ]);

  const debounced = useDebouncedCallback(
    (e:any) => {
      const currentWeek = Math.round(e.target.scrollLeft/e.target.clientWidth);
      setActiveDay(isDateDisabled(weeks[currentWeek][0].monthDay) ? new Date() : weeks[currentWeek][0].monthDay);
      if (currentWeek >= weeks.length - 1) {
        const nextWeeksFirstDay = getMonday(dateFns.addDays(weeks[currentWeek][6].monthDay, 1));
        setWeeks([...weeks, getWeek(nextWeeksFirstDay)])
      }
    },
    50
  );

  return (
    <div className={styles.calendarDays} onScroll={debounced}>
      {weeks.map((week) => (
        <div className={styles.calendarWeek} >
          {week.map((day) => 
            <CalendarDay
              active={getformatedDate(day.monthDay) === getformatedDate(activeDay)} 
              onClick={() => setActiveDay(day.monthDay)}
              weekDay={weekDayMappings[day.weekday]}
              monthNumber={day.monthDay.getDate()}
              disabled={day.isDisabled} 
            />
          )}
        </div>
      ))}
    </div>
  );
}