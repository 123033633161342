import React from 'react';
import axios from 'axios';

import { PageTitle, PageWrapper } from '../../../components/page';
import { OrderCard, OrdersCounter } from '../../../components/order';
import { FrameButton } from '../../../components';

interface User {
  id: string,
  email: string,
  displayName: string,
  pictureUrl: string,
  phoneNumber: string,
}

interface Order {
  id: string,
  status: 'success' | 'pending' | 'failed',
  trainee: User,
  createdAt: string,
  timeSlotsCount: number
}

export const TrainerOrders = () => {
  const [orders, setOrders] = React.useState<Array<Order>>([])

  React.useEffect(() => {
  axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/order/trainer-orders`,
      withCredentials: true,
      params: {
      }
  }).then((response) => {
      if(response.data.msg) {
          console.log('Not Authenticated');
      } else {
          setOrders(response.data);
      }
  })

}, []);

  return (
    <PageWrapper className={'navbarPadding'}>
      <PageTitle>Mokėjimai</PageTitle>
      <OrdersCounter count={orders.length} />
      { orders && orders.map((order) => 
        <>
          <OrderCard order={order} />
          <FrameButton name='Peržiūrėti mokėjimą' path={'/order/' + order.id} />
        </>
      )}
    </PageWrapper>
  )
}
