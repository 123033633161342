import * as React from "react";
import styles from "./user.order.module.scss";
import { useParams } from 'react-router-dom';
import { PageTitle, PageWrapper } from "../../../components/page";
import axios from "axios";
import { PaymentTotalPrice, PaymentTraining, PaymentTrainingsCounter } from "../../../components/payment";
import { OrderInfo } from "../../../components/order";
import { Field, Status } from "../../../components";

interface Trainer {
    id: string,
    email: string,
    displayName: string,
    pictureUrl: object,
    phoneNumber: string,
}

interface Timeslot {
    id: string;
    date: string;
    time: string;
    price: number;
    address: string;
    status: string;
  }

interface Order {
    id: string,
    status: 'success' | 'pending' | 'failed',
    trainer: Trainer,
    createdAt: string,
    timeSlots: Array<Timeslot>;
}

const getOrderTotalSum = (selectedTimeslots: Timeslot[]): number => {
    return selectedTimeslots.length ? selectedTimeslots.map(a => a.price).reduce((a, b) => a + b) : 0;
  }

const getStatusLithuanianTranslation = {
    success: 'Apmokėta sėkmingai',
    pending: 'Laukiama apmokėjimo',
    failed: 'Apmokėjimas nepavyko',
};

const orderView = (order: Order) => (<>
    <PaymentTrainingsCounter count={order.timeSlots.length} />
    <Field icon={<Status status={order.status} />} text={getStatusLithuanianTranslation[order.status]} size='big' />
    <div className={styles.orderInfoWrapper}>
    <OrderInfo
        name={order.trainer.displayName}
        phoneNumber={order.trainer.phoneNumber}
        createdAt={order.createdAt} 
        preName="Treneris"
    />
    </div>
    {order.timeSlots.map((item: Timeslot) =>
        <PaymentTraining userType="client" timeSlot={item} />
    )}
    <PaymentTotalPrice price={getOrderTotalSum(order.timeSlots)} />
</>);

export const UserOrder: React.FC = () => {
    const [order, setOrder] = React.useState<Order>();
    let { orderId } = useParams();

    React.useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/user-order-details/${orderId}`,
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            if (response.data.msg) {
                console.log('Not Authenticated');
            } else {
                setOrder(response.data);
            }
        })
    }, []);


    return (
        <PageWrapper className={'navbarPadding'} >
            <PageTitle>Mokėjimas</PageTitle>
            {order && orderView(order)}
        </PageWrapper>);
}