import * as React from "react";
import { ButtonInput, TextInput } from "../../components";
import styles from "./profile.module.scss";
import { PageBottomWrapper, PageTitle, PageWrapper } from "../../components/page";
import { useUserData } from "../../../data.providers/user.data.context";
import { useForm } from 'react-hook-form';
import axios from "axios";

function getErrorMessage(error: any): string | undefined {
  if (error && 'message' in error) {
    return String(error.message);
  }
  return undefined;
}


export const Profile: React.FC = () => {
  const { user, updateUser } = useUserData();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const profileInputs = (user: any) => <>
    <TextInput
      title='Vardas, Pavardė'
      id='displayName'
      type='text'
      defaultValue={user.displayName}
      errorText={getErrorMessage(errors.displayName)}
      {...register('displayName', { required: 'Įveskite vardą ir pavardę.' })}
    />
    <TextInput 
      title='Telefono numeris'
      id='phoneNumber'
      type='tel'
      defaultValue={user.phoneNumber}
      errorText={getErrorMessage(errors.phoneNumber)}
      {...register('phoneNumber', { 
        required: 'Įveskite telefono numerį.',
        pattern: {
          value: /^[\d\s\+\-\(\)]{9,15}$/,
          message: 'Neteisingai įvestas telefono numeris'
        }
      })}
    />
    <TextInput
      title='Elektroninis paštas'
      id='email'
      type='email'
      defaultValue={user.email}
      errorText={getErrorMessage(errors.email)}
      {...register('email', {
        required: 'Įveskite elektroninio pašto adresą.',
        pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'Neteisingai įvestas elektroninio pašto adresas.'
        }
      })}
    />
  </>

  const onSubmit = (data: any) => {
    console.log(data);
    axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_BACKEND_URL}/user/update`,
      withCredentials: true,
      data
    }).then((response) => {
      if(response.data.msg) {
          console.log('Not Authenticated');
      } else {
        updateUser(response.data);
        window.location.assign(`${process.env.REACT_APP_FRONTEND_URL}`);
      }
    }).catch((error) => {
      console.error('Error updating user:', error);
    });
  };

  return (<div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper>
        <PageTitle>Patikslinkite savo duomenis</PageTitle>
          {user && profileInputs(user)}
        </PageWrapper>
        <PageBottomWrapper>
          <ButtonInput type='submit' >Išsaugoti</ButtonInput>
        </PageBottomWrapper>
      </form>
    </div>
  );
};