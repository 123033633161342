import React from 'react'
import * as dateFns from "date-fns";
import axios from "axios";
import styles from './trainer.calendar.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';
import { CalendarDays } from '../calendar/calendar.days';
import { ButtonFullWidth, Title } from '../../components';
import { CalendarTraining } from '../../components/trainer.calendar';

const monthsInLithuanian = [
    "Sausis",
    "Vasaris",
    "Kovas",
    "Balandis",
    "Gegužė",
    "Birželis",
    "Liepa",
    "Rugpjūtis",
    "Rugsėjis",
    "Spalis",
    "Lapkritis",
    "Gruodis"
];

const formatTimeString = (time: string) => time.slice(0, -3);

interface Timeslot {
    id: string,
    time: string,
    status: 'success' | 'pending' | 'failed',
    name: string,
}

const getTraningsLithuanianTranslation = ( count: number ): string => {
    switch (count % 10) {
      case 1: 
        return 'apmokėta treniruotė';
      case 0:
        return 'apmokėtų treniruočių';
      default:
        return 'apmokėtos treniruotės'
    }
}

export const TrainerCalendar = () => {
    const [activeDate, setActiveDate] = React.useState(new Date());
    const [timeslots, setTimeslots] = React.useState<Array<Timeslot>>([])

    React.useEffect(() => {
        const date = dateFns.formatISO(activeDate);
        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/trainer-trainings-by-date`,
          params: {
            date: date.split('T')[0]
          },
          withCredentials: true
        }).then((response) => {
            setTimeslots(response.data);
        })
      }, [activeDate]);

    return (
        <PageWrapper className={styles.calendarWrapper}>
            <PageTitle>{monthsInLithuanian[(activeDate).getMonth()]}</PageTitle>
            <CalendarDays setActiveDate={setActiveDate} />
            <Title size='small'>{timeslots.length} {getTraningsLithuanianTranslation(timeslots.length)}</Title>
            <div className={styles.registartionsWrapper}>
                {
                    timeslots.length ? timeslots.map(({status, time, name, id}) => 
                        <CalendarTraining
                        training={{
                            status,
                            time: formatTimeString(time),
                            name
                        }}
                        path={'/training/' + id}
                        />
                    ) :
                    <div className={styles.noFreeTimeslotsText}>Treniruočių šią dieną nėra. Pasirinkite kitą dieną</div>
                }
            </div>
            <div className={styles.timeButtonWrapper}>
                <ButtonFullWidth
                    name='Papildyti laisvus laikus'
                    path='/trainer/time/selector'
                />
            </div>
        </PageWrapper>
    )
}
