import React, { useState, ReactNode } from 'react';
import styles from './tabs.module.scss';
import TabItem from './tab.item';

// Define the props for each tab item
interface ITabItem {
    label: string | ReactNode;
    content: ReactNode;
}

// Define the props for the Tabs component
interface ITabsProps {
    items: ITabItem[];
}

export const Tabs: React.FC<ITabsProps> = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <>
            <div className={styles.tabs}>
                {items.map((item, index) => (
                    <TabItem
                        key={index}
                        index={index}
                        label={item.label}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                ))}
            </div>
            {items[activeIndex].content}
        </>
    );
};
