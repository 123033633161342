import React from 'react'
import styles from './calendar.training.module.scss'
import { Frame } from '../frame'
import { Status } from '../status'
import { ReactComponent as ExpandMore } from './expand_more.svg'
import { useNavigate } from 'react-router-dom';

interface ITraining {
  status: 'success' | 'pending' | 'failed',
  time: string,
  name: string,
}

interface ICalendarTraining {
  training: ITraining
  path: string,
}

export const CalendarTraining: React.FC<ICalendarTraining> = ({ training, path }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(path);
  };

  return (
      <Frame onClick={handleClick} className={styles.calendarTraining}>
        <div className={styles.trainingInfo}>
          <Status status={training.status} />
          {training.time}
          <div className={styles.clientName}>{training.name}</div>
        </div>
        <ExpandMore />
      </Frame>
  )
}
