import * as React from "react";
import styles from "./profile.image.module.scss";

interface IProfileImage {
  path?: string;
}

export const ProfileImage: React.FC<IProfileImage> = ({ path }: any) => {
  return (
    <div className={styles.profileImageWrapper}>
      <img src={`${process.env.REACT_APP_FRONTEND_URL}/${path}`} className={styles.profileImage} alt="profile" />
    </div>
  );
};