
import { AppPaths } from "../app.paths.enum";
import { Trainers, Trainer } from "../../../pages";

import * as React from "react";

interface AppRoute {
    path: string;
    element: React.FC;
}

export const publicRoutes: AppRoute[] = [
  { path: AppPaths.TRAINERS, element: Trainers },
  { path: AppPaths.TRAINER, element: Trainer },
];