import * as React from "react";
import { Navbar, Title, UserCard } from "../../components";
import styles from "./subscriptions.module.scss";
import { PageTitle, PageWrapper } from "../../components/page";
import { SubscriptionCard } from "../../components/subscription";


export const Subscriptions: React.FC = () => {
  return (
    <div className={styles.subscriptionsContainer}>
      <PageWrapper className={'navbarPadding'}>
        <PageTitle>Abonementai</PageTitle>
        <Title size="small">1 abonementas</Title>
        <UserCard
          user={{
            id: '1',
            displayName: "Kostas Petrošius",
            phoneNumber: "+370 123 45 678",
            location: "SportGates",
            pictureUrl: "profile.png",
          }}
          className={styles.subscriptionsUserCard}
        />
        <SubscriptionCard
          subscription={{
            id: '1',
            status: 'success',
            activeAt: '2024-07-01T12:35:55.941Z',
            validAt: '2024-07-02T12:35:55.941Z',
            location: 'SportGates',
            isConfirmed: true,
            days: [{ day: 'Pirmadienis', times: [['12:00','13:00'],['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00','13:00'],['14:00', '15:00']] }]
          }}
          withButton
          path={`/subscription/${1}`}
          className={styles.subscriptionCard}
        />
      </PageWrapper>
      <Navbar active='subscriptions' />
    </div>
  );
};