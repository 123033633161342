import * as React from "react";
import { AppRoutes } from "../components";
import { AppHeader } from "../components";
import axios from "axios";
import { useUserData } from "../../data.providers/user.data.context";

export const Layout: React.FC = React.memo(() => {
  const { user, updateUser } = useUserData();

  React.useEffect(() => {
    axios({
      method: 'get',
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND_URL,
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/user/current`,
    }).then((response) => {
      if(response.data.msg !== 'Not Authenticated') {
        updateUser(response.data);
      } else {
        updateUser(false);
      }
    })
  }, []);
  
  return (
    <>
      <AppHeader/>
      <AppRoutes/>
    </>
  )
});