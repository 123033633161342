import * as React from "react";
import { Layout } from "./layout";
import "./app.scss";
import { BrowserRouter } from "react-router-dom";
import { UserDataProvider } from "../../data.providers/user.data.context";

export const App: React.FC = () => {
  return (
    <div className="wrapper">
      <UserDataProvider>
        <BrowserRouter>
            <Layout/>
        </BrowserRouter>
      </UserDataProvider>
    </div>
  );
}
