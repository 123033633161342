import { BackButton } from "../back.button";
import styles from "./page.title.module.scss";

export const PageTitle = ({ backButton, ...props }: any) => (
    <div className={styles.pageTitleWithBackContainer}>
        {backButton && <BackButton />}
        <div
            className={styles.pageTitle}
            {...props}
        />
    </div>
);