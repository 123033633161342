import React from 'react'
import styles from './location.card.module.scss';
import { ReactComponent as PlaceSvg } from './place.svg';

interface ILocation {
  id: string,
  displayName: string;
}

interface ILocationCard {
  location: ILocation
  price: string;
  onClick: () => void
}

export const LocationCard: React.FC<ILocationCard> = ({ location, onClick, price }) => {
  return (
    <div className={styles.locationCard} onClick={onClick}>
        <PlaceSvg />
        <div>
            <div  className={styles.location}>{location.displayName}</div>
            <div className={styles.price}>Treniruotės kaina: {price} €</div>
        </div>
    </div>
  )
}
