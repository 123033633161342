import * as React from "react";
import * as dateFns from "date-fns";
import styles from "./calendar.module.scss";
import { ButtonFullWidth } from "../../components";
import { CalendarDays } from "./calendar.days";
import { CalendarHours } from "./calendar.hours";
import axios from "axios";
import { PageBottomWrapper, PageTitle, PageWrapper } from "../../components/page";
import { useParams } from "react-router-dom";

interface Timeslot {
  id: string,
  date: string,
  time: string,
  price: number,
  address: string,
}

const monthsInLithuanian = [
  "Sausis",
  "Vasaris",
  "Kovas",
  "Balandis",
  "Gegužė",
  "Birželis",
  "Liepa",
  "Rugpjūtis",
  "Rugsėjis",
  "Spalis",
  "Lapkritis",
  "Gruodis"
];

const getformatedDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const Calendar: React.FC = () => {
  let { trainerId } = useParams();
  const [timeslots, setTimeslots] = React.useState<Array<Timeslot>>([])
  const [activeDate, setActiveDate] = React.useState(new Date());
  const [selectedTimeslots, setSelectedTimeslots] = React.useState<Array<{date: string, time: string}>>([]);

  React.useEffect(() => {
    const date = dateFns.formatISO(activeDate);
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/${trainerId}/available`,
      params: {
        date: date.split('T')[0]
      }
    }).then((response) => {
      setTimeslots(response?.data)
    })

  }, [activeDate]);

  const onTimeslotSelect = (timeslot: Timeslot) => {
    const checked = selectedTimeslots.some((selected) => selected.time === timeslot.time && selected.date === getformatedDate(activeDate));
    if (!checked) {
      setSelectedTimeslots([...selectedTimeslots, {...timeslot , date: getformatedDate(activeDate) }]);
    } else {
      setSelectedTimeslots(selectedTimeslots.filter((selected) => !(selected.time === timeslot.time && selected.date === getformatedDate(activeDate))))
    }
  }
  const activeDateSelectedTimeSlots = selectedTimeslots.filter((hour) => hour.date === getformatedDate(activeDate));

  const isSelected = (timeslot: Timeslot): boolean => activeDateSelectedTimeSlots.some((selected) => selected.time === timeslot.time);

  return (<>
    <PageWrapper className={styles.calendarWrapper}>
      <PageTitle>{monthsInLithuanian[(activeDate).getMonth()]}</PageTitle>
      <CalendarDays setActiveDate={setActiveDate} />
      {timeslots.length ? timeslots.map((timeslot) => 
        <CalendarHours
          onClick={onTimeslotSelect}
          timeslot={timeslot}
          isSelected ={isSelected(timeslot)}
        />
      ) :
      <div className={styles.noFreeTimeslotsText}>
        Laisvų laikų šią dieną nėra. Pasirinkite kitą dieną.
      </div>
      }
      <div className={styles.space}> </div>
    </PageWrapper>
    <PageBottomWrapper> 
      { selectedTimeslots.length > 0 &&
        <ButtonFullWidth name='Eiti į apmokėjimą' path='/payment' state={{ selectedTimeslots, trainerId }} ></ButtonFullWidth>
      }
    </PageBottomWrapper>
  </>
  );
};