import * as React from "react";
import styles from "./calendar.hours.module.scss";
import { CheckboxCustom, Frame } from "../../components";
import classNames from "classnames";

interface Timeslot {
  id: string;
  date: string;
  time: string;
  price: number;
  address: string;
}

interface ICalendarHours {
  onClick: Function;
  isSelected: boolean;
  timeslot: Timeslot;
}

const formatTimeString = (time: string) => time.slice(0, -3);

export const CalendarHours: React.FC<ICalendarHours> = (props) => {
  const { timeslot, isSelected, onClick } = props;
  return (<Frame
    className={styles.calendarHoursItem}
    onClick={() => {onClick(timeslot)}}
  >
    <div className={styles.calendarHoursItemCheckmark}>
      <CheckboxCustom checked={isSelected}/>
    </div>
    <div className={classNames(styles.calendarHoursItemTimeAndAddress)}>
      <div className={classNames(styles.time, { [styles.selected] : isSelected })}>
        {formatTimeString(timeslot.time)}
      </div>
      <div className={styles.address}>
        {timeslot.address}
      </div>
    </div>
    <div className={styles.price}>&#8364; {timeslot.price}</div>
  </Frame>);
};
