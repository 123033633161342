
import { AppPaths } from "../app.paths.enum";
import { TrainingTypes, Calendar, Profile, Payment, PaymentSuccess, TrainingCancellation, UserOrders, UserOrder, UserTrainings } from "../../../pages";
import * as React from "react";

interface AppRoute {
    path: string;
    element: React.FC;
}

export const userRoutes: AppRoute[] = [
  { path: AppPaths.TRAINING_TYPES, element: TrainingTypes },
  { path: AppPaths.CALENDAR, element: Calendar },
  { path: AppPaths.PROFILE, element: Profile },
  { path: AppPaths.PAYMENT, element: Payment },
  { path: AppPaths.PAYMENT_SUCCESS, element: PaymentSuccess },
  { path: AppPaths.TRAININGS, element: UserTrainings },
  { path: AppPaths.TRAINING_CANCELLATION, element: TrainingCancellation },
  { path: AppPaths.ORDERS, element: UserOrders },
  { path: AppPaths.ORDER, element: UserOrder },
];