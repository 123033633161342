
import { AppPaths } from "../app.paths.enum";
import { LocationCreate, LocationEditor, UserSubscription, SubscriptionCreate, SubscriptionEdit, SubscriptionRegistration, Subscriptions, TrainerCalendar, TrainerOrder, TrainerOrders, TrainerSubscriptions, TrainerTimeSelector, TrainerTraining, TrainerTrainingCancellation, TrainerTrainings, Profile } from "../../../pages";
import * as React from "react";

interface AppRoute {
    path: string;
    element: React.FC;
}

export const trainerRoutes: AppRoute[] = [
  { path: AppPaths.TRAININGS, element: TrainerTrainings },
  { path: AppPaths.TRAINING, element: TrainerTraining },
  { path: AppPaths.TRAINER_TRAINING_CANCELLATION, element: TrainerTrainingCancellation },

  { path: AppPaths.ORDERS, element: TrainerOrders },
  { path: AppPaths.ORDER, element: TrainerOrder },

  { path: AppPaths.SUBSCRIPTIONS, element: Subscriptions },
  { path: AppPaths.SUBSCRIPTION, element: UserSubscription },
  { path: AppPaths.SUBSCRIPTION_REGISTRATION, element: SubscriptionRegistration },
  { path: AppPaths.TRAINER_SUBSCRIPTIONS, element: TrainerSubscriptions },
  { path: AppPaths.SUBSCRIPTION_CREATE, element: SubscriptionCreate },
  { path: AppPaths.SUBSCRIPTION_EDIT, element: SubscriptionEdit },

  { path: AppPaths.TRAINER_CALENDAR, element: TrainerCalendar },
  { path: AppPaths.TRAINER_TIME_SELECTOR, element: TrainerTimeSelector },
  { path: AppPaths.PROFILE, element: Profile },

  { path: AppPaths.LOCATION_EDIT, element: LocationEditor },
  { path: AppPaths.LOCATION_CREATE, element: LocationCreate },
];