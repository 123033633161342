import React from 'react'
import { Frame } from '../frame';
import styles from './order.card.module.scss';
import { FrameButton } from '../button';
import { Title } from '../title';
import { Field } from '../field';
import { Status } from '../status';
import { dateFormat } from '../../utils/date.format.util';
import { OrderInfo } from './order.info';


// id: string,
// email: string,
// displayName: string,
// pictureUrl: string,
// phoneNumber: string,


interface User {
    id: string,
    email: string,
    phoneNumber: string,
    displayName: string,
    pictureUrl: string,
    type: string,
}

interface IOrder {
    id: string,
    status: 'success' | 'pending' | 'failed',
    createdAt: string,
    timeSlotsCount: number,
    user?: User,
    trainer?: User,
}

interface IOrderCard {
    order: IOrder;
}

const getStatusLithuanianTranslation = {
    success: 'Apmokėta sėkmingai',
    pending: 'Laukiama apmokėjimo',
    failed: 'Apmokėjimas nepavyko',
};

const getTraningsLithuanianTranslation = ( count: number ): string => {
    switch (count % 10) {
      case 1: 
        return 'treniruotė';
      case 0:
        return 'treniruočių';
      default:
        return 'treniruotės'
    }
}

export const OrderCard: React.FC<IOrderCard> = ({ order : { timeSlotsCount, status, user, trainer, createdAt } }) => 
(<>
    <Frame className={styles.orderCard}>
        <Title size='big' className={styles.orderTitle}>{timeSlotsCount} {getTraningsLithuanianTranslation(timeSlotsCount)}</Title>
        <Field icon={<Status status={status} />} text={getStatusLithuanianTranslation[status]} size='big' />

        { user && <OrderInfo
            name={user.displayName}
            phoneNumber={user.phoneNumber}
            createdAt={createdAt}
            preName={'Klientas'}
        />}

        { trainer && <OrderInfo
            name={trainer.displayName}
            phoneNumber={trainer.phoneNumber}
            createdAt={createdAt}
            preName={'Treneris'}
        />}
    </Frame>
</>
)
