import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./button.full.width.module.scss";
import classNames from "classnames";

type IButtonFullWidth = {
  name: string;
  state?: {};
  className?: string;
  styleType?: 'outlined' | 'default',
  size?: 'small' | 'medium',
} & (
  | { path: string; onClick?: never }  // Require `path`, disallow `onClick`
  | { onClick: () => void; path?: never }  // Require `onClick`, disallow `path`
);

export const ButtonFullWidth: React.FC<IButtonFullWidth> = ({ name, path, onClick, styleType = 'default', className, state, size = 'medium' }) => {
  return (
    <>
      {path && (
        <Link to={path} state={state} >
          <button className={classNames(styles.buttonFullWidth, styles[styleType], styles[size], className)}>{name}</button>
        </Link>
      )}
      {onClick && (
        <button className={classNames(styles.buttonFullWidth, styles[styleType], styles[size], className)} onClick={onClick}>{name}</button>
      )}
    </>
  )
}
