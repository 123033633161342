export enum AppPaths {
    TRAINERS = "/trainers",
    TRAINER = "/t/:trainerId",
    TRAINING_TYPES = "/t/:trainerId/training/types",
    CALENDAR = "/t/:trainerId/calendar",
    PAYMENT = "/payment",
    PAYMENT_SUCCESS = "/payment/success",
    TRAININGS = "/trainings",
    TRAINING = "/training/:trainingId",
    ORDERS = "/orders",
    ORDER = "/order/:orderId",
    PROFILE = "/profile",
    TRAINING_CANCELLATION = "/trainings/cancellation",
    TRAINER_TRAINING_CANCELLATION = "/trainer/trainings/cancellation",
    SUBSCRIPTIONS = "/subscriptions",
    SUBSCRIPTION = "/subscription/:subscriptionId",
    SUBSCRIPTION_REGISTRATION = "/t/:trainerId/subscription/registration",
    TRAINER_SUBSCRIPTIONS = "/trainer/subscriptions",
    TRAINER_CALENDAR = "/trainer/calendar",
    TRAINER_TIME_SELECTOR = "/trainer/time/selector",
    SUBSCRIPTION_CREATE = "/subscription/create",
    SUBSCRIPTION_EDIT = "/subscription/edit",
    LOCATION_EDIT = "/location/edit",
    LOCATION_CREATE = "/location/create",
}