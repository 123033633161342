import React from 'react'
import styles from './subscription.card.module.scss';
import { ReactComponent as LocationSvg } from './location.svg';
import { ReactComponent as CalenderSvg } from './calender.svg';
import { FrameButton } from '../button';
import { Frame } from '../frame';
import classNames from 'classnames';
import { Field } from '../field';
import { WeekdayWithTimes } from './weekday.with.times';
import { Status } from '../status';
import { dateFormat } from '../../utils/date.format.util';

interface ISubscription {
    subscription: {
        id: string,
        status: 'success' | 'pending' | 'failed',
        activeAt?: string,
        validAt?: string,
        location: string,
        isConfirmed: boolean,
        days: { day: string, times: string[][] }[]
    },
    withButton?: boolean;
    className?: string;
    path?: string;
}

const getStatusLithuanianTranslation = {
    success: 'Apmokėta sėkmingai',
    pending: 'Laukiama apmokėjimo',
    failed: 'Apmokėjimas nepavyko',
};

export const SubscriptionCard: React.FC<ISubscription> = ({ subscription, withButton, path, className }) => {
    return (
        <>
            <Frame className={classNames(styles.subscriptionCard, className)}>
                <div className={styles.subscriptionInfoWrapper}>
                    <Field
                        icon={<Status status={subscription.status} />}
                        text={subscription.isConfirmed && subscription.activeAt ? <>Aktyvus nuo {dateFormat(subscription.activeAt)}</> : getStatusLithuanianTranslation[subscription.status]}
                        size='small'
                    />
                    <Field
                        icon={<LocationSvg />}
                        text={subscription.location}
                        size='small'
                    />
                    {subscription.isConfirmed && subscription.validAt && (
                        <Field
                            icon={<CalenderSvg />}
                            text={<>Galioja nuo: {dateFormat(subscription.validAt)}</>}
                            size='small'
                        />
                    )}
                </div>
                {subscription.days.map((item, index) => (
                    <WeekdayWithTimes
                        key={index}
                        dayTime={item}
                    />
                ))}
            </Frame>
            {withButton && (
                path && subscription.isConfirmed ? (
                    <FrameButton className={styles.subscriptionButton} name='Peržiūrėti abonementą' path={path} />
                ) : (
                    <div className={styles.buttonsWrapper}>
                        <button className={styles.button}>Patvirtinti</button>
                        <button className={styles.button}>Atmesti</button>
                    </div>
                )
            )}
        </>
    )
}
