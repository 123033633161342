import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./payment.module.scss";
import { useStripe, useElements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useUserData } from "../../../data.providers/user.data.context";
import { PageBottomWrapper, PageTitle, PageWrapper } from "../../components/page";
// import { ButtonFullWidth } from "../../components";
import { PaymentTotalPrice, PaymentTraining, PaymentTrainingsCounter } from "../../components/payment";

interface Timeslot {
  id: string;
  date: string;
  time: string;
  price: number;
  address: string;
}

const getOrderTotalSum = (selectedTimeslots: Timeslot[]): number => {
  return selectedTimeslots.length ? selectedTimeslots.map(a => a.price).reduce((a, b) => a + b) : 0;
}

export const Payment: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { selectedTimeslots = [], trainerId } = state;
  const { user } = useUserData();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = React.useState<any>(null);

  React.useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'LT',
      currency: 'eur',
      total: {
        label: 'Total',
        amount: getOrderTotalSum(selectedTimeslots) * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result: any) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e: any) => {
      const { error: backendError, clientSecret, orderId } = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/payment/create-intent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ timeslots: selectedTimeslots, userId: user.id, trainerId }),
        }
      ).then((r) => r.json());

      if (backendError) {
        console.log(backendError.message);
        return;
      }

      const {
        error: stripeError,
        paymentIntent,
      } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: e.paymentMethod.id,
      }, { handleActions: false });

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(stripeError.message);
        e.complete('fail');
        return;
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      e.complete('success');

      navigate('/payment/success', {
        state: {
          paymentIntentId: paymentIntent.id,
          paymentStatus: paymentIntent.status,
          orderId
        }
      });
    });
  }, [stripe, elements]);

  return (<>
    <PageWrapper className={styles.paymentContainer}>
      <PageTitle>Apmokėjimas</PageTitle>
      <PaymentTrainingsCounter count={selectedTimeslots.length} />
      {selectedTimeslots.map((item: Timeslot) =>
        <PaymentTraining userType="client" timeSlot={item} />
      )}
      <PaymentTotalPrice price={getOrderTotalSum(selectedTimeslots)} />
      <div className={styles.space}> </div>
    </PageWrapper>
    <PageBottomWrapper>
      {/* <ButtonFullWidth name='Mokėti kitu būdu' path='/' ></ButtonFullWidth> */}
      {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
    </PageBottomWrapper>
  </>
  );
};