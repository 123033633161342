import React from 'react'
import { PageTitle, PageWrapper } from '../../components/page'
import { ButtonFullWidth, Tabs, Title, UserCard } from '../../components'
import styles from './trainer.subscriptions.module.scss';
import { SubscriptionCard } from '../../components/subscription';

export const TrainerSubscriptions = () => {

    

    function RenderNotConfirmed() {
        return (
            <>
                <Title size='small'>2 nepatvirtinti abonementai</Title>
                <UserCard
                    user={{
                        id: '1',
                        displayName: "Kostas Petrošius",
                        phoneNumber: "+370 123 45 678",
                        pictureUrl: "profile.png",
                        email: 'kostas@gmail.com'
                    }}
                    className={styles.subscriptionsUserCard}
                />
                <SubscriptionCard
                    subscription={{
                        id: '1',
                        status: 'success',
                        location: 'SportGates',
                        days: [{ day: 'Pirmadienis', times: [['12:00','13:00'],['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00','13:00'],['14:00', '15:00']] }],
                        isConfirmed: false
                    }}
                    className={styles.subscriptionCard}
                    withButton
                />

                <UserCard
                    user={{
                        id: '1',
                        displayName: "Kostas Petrošius",
                        phoneNumber: "+370 123 45 678",
                        pictureUrl: "profile.png",
                        email: 'kostas@gmail.com'
                    }}
                    className={styles.subscriptionsUserCard}
                />
                <SubscriptionCard
                    subscription={{
                        id: '2',
                        status: 'success',
                        location: 'SportGates',
                        days: [{ day: 'Pirmadienis', times: [['12:00','13:00'],['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00','13:00'],['14:00', '15:00']] }],
                        isConfirmed: false
                    }}
                    className={styles.subscriptionCard}
                    withButton
                />
            </>
        )
    }

    function RenderConfirmed() {
        return (
            <>
                <Title size='small'>2 nepatvirtinti abonementai</Title>
                <UserCard
                    user={{
                        id: '1',
                        displayName: "Kostas Petrošius",
                        phoneNumber: "+370 123 45 678",
                        location: "SportGates",
                        pictureUrl: "profile.png",
                    }}
                    className={styles.subscriptionsUserCard}
                />
                <SubscriptionCard
                    subscription={{
                        id: '1',
                        status: 'success',
                        activeAt: '2024-07-01T12:35:55.941Z',
                        validAt: '2024-07-02T12:35:55.941Z',
                        location: 'SportGates',
                        isConfirmed: true,
                        days: [{ day: 'Pirmadienis', times: [['12:00','13:00'],['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00','13:00'],['14:00', '15:00']] }]
                    }}
                    path={`/trainer/subscription/${1}`}
                    className={styles.subscriptionCard}
                    withButton
                />
                <UserCard
                    user={{
                        id: '2',
                        displayName: "Kostas Petrošius",
                        phoneNumber: "+370 123 45 678",
                        location: "SportGates",
                        pictureUrl: "profile.png",
                    }}
                    className={styles.subscriptionsUserCard}
                />
                <SubscriptionCard
                    subscription={{
                        id: '2',
                        status: 'success',
                        activeAt: '2024-07-01T12:35:55.941Z',
                        validAt: '2024-07-02T12:35:55.941Z',
                        location: 'SportGates',
                        isConfirmed: true,
                        days: [{ day: 'Pirmadienis', times: [['12:00','13:00'],['14:00', '15:00']] }, { day: 'Trečiadienis', times: [['12:00','13:00'],['14:00', '15:00']] }]
                    }}
                    path={`/trainer/subscription/${2}`}
                    className={styles.subscriptionCard}
                    withButton
                />
            </>
        )
    }

    const tabs = [
        {
           label: <>Nepatvirtinti <span className={styles.badge}>2</span></>,
           content: <RenderNotConfirmed />
        },
        {
            label: 'Patvirtinti',
            content: <RenderConfirmed /> 
        }
    ]
  return (
    <PageWrapper className={'navbarPadding'}>
        <PageTitle>Abonementai</PageTitle>
        <ButtonFullWidth name='Sukurti naują abonementą' path='/subscription/create' className={styles.signupButton} />
        <Tabs items={tabs} />
    </PageWrapper>
  )
}
