import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./frame.button.module.scss";
import classNames from "classnames";

interface IFrameButton {
  name: string;
  path: string;
  state?: {};
  className?: string;
}

export const FrameButton: React.FC<IFrameButton> = ({ name, path, state, className }) =>
  <Link className={styles.frameButtonLink} to={path} state={state} >
    <button className={classNames(styles.frameButton, className)}>{name}</button>
  </Link>