import React from 'react'
import { PageTitle, PageWrapper } from '../../components/page'
import { useParams } from 'react-router-dom'
import styles from './training.types.module.scss'
import { Title, TrainingTypeCard } from '../../components'
export const TrainingTypes: React.FC = () => {
  let { trainerId } = useParams();
  return (
    <PageWrapper>
      <PageTitle backButton>Registracija</PageTitle>
      <Title size='big'>Pasirinkite, kokio tipo treniruotėms norėtumėte užsiregistruoti</Title>
      <div className={styles.trainingTypesContainer}>
        <TrainingTypeCard
          title='Vienkartinė treniruotė'
          description='Pasirinkite jums tinkamą datą ir laiką, apmokėkite ir apsilankykite treniruotėje.'
          path={`/t/${trainerId}/calendar`}
        />
        <TrainingTypeCard
          title='Reguliarūs apsilankymai'
          description='Kai užsakymą patvirtins treneris ir gausime apmokėjimą, apsilankymai bus automatiškai sugeneruojami kiekvieną mėnesį.'
          path={`/t/${trainerId}/subscription/registration`}
        />
      </div>
    </PageWrapper>
  )
}
