import React, { ChangeEvent } from 'react'
import { ReactComponent as InputActive } from './radio_button_checked.svg';
import { ReactComponent as InputInactive } from './radio_button_unchecked.svg';
import styles from './radio.input.module.scss';
import classNames from 'classnames';

interface IRadioInput {
    label: string,
    value: number | string,
    onChange: (ev: ChangeEvent<HTMLInputElement>) => void,
    checked?: boolean,
    className?: string,
}

export const RadioInput: React.FC<IRadioInput> = ({ label, value, onChange, checked, className }) => {
    return (
        <label className={classNames(styles.radioInputWrapper, className)}>
            <input
                type="radio"
                className={styles.radioInput}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <span className={styles.radioCustom}>
                <InputInactive className={styles.inactive} />
                <InputActive className={styles.active} />
            </span>
            {label}
        </label>
    )
}
