import React from 'react';
import axios from 'axios';
import styles from './trainer.trainings.module.scss';
import { FrameButton, Tabs, Title, TrainingCard, UserCard } from '../../../components';
import { PageTitle, PageWrapper } from '../../../components/page';

interface User {
    id: string,
    email: string,
    displayName: string,
    pictureUrl: string,
    phoneNumber: string,
  }
  
  interface Training {
    id: string,
    date: string,
    time: string,
    address: string,
    status: 'pending' | 'success' | 'failed',
    user: User,
  }

export const TrainerTrainings = () => {

    const [trainings, setTrainings] = React.useState<Array<Training>>([]);
    const [trainingsType, setTrainingsType] = React.useState<String>('future');

    React.useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/trainer-timeslots`,
            withCredentials: true,
            params: {
                timeFrame: trainingsType
            }
          }).then((response) => {
              if(response.data.msg) {
                console.log('Not Authenticated');
              } else {
                setTrainings(response.data);
              }
          })
      }, [trainingsType]);


    function RenderUpcoming({timeFrame = 'future'}) {
        setTrainingsType(timeFrame);
        return <> 
        <Title size='small'>{trainings.length} treniruotės</Title>
        {trainings.map((training) => {
            const {user, ...rest} = training;
            return <>
                <TrainingCard
                    training={rest}
                    userType='trainer'
                />
                <UserCard
                    user={user}
                    className={styles.trainingUserCard}
                />
                <FrameButton path={`/training/${training.id}`} name='Peržiūrėti treniruotę' />
            </>
        })}
        </>
    }

    const tabs = [
        {
            label: 'Būsimos',
            content: <RenderUpcoming  timeFrame='future'/>
        },
        {
            label: 'Įvykusios',
            content: <RenderUpcoming  timeFrame='past'/>
        }
    ]

    return (
        <PageWrapper>
            <PageTitle>Mano treniruotės</PageTitle>
            <Tabs items={tabs} />
        </PageWrapper>
    )
}
